.over_view_page {
    margin-top: 30px;
    .over_view_head_content {
        padding-left: 20px;
    }
}
.over_grid_box{
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.over_view_box_sec {
    background-color: #f5f5f5;
    margin: 0px 12px;
    padding: 18px;
    color: white;
    box-shadow: rgb(14 30 31 / 7%) 0px 2px 4px 0px, rgb(14 24 32 / 22%) 0px 2px 11px 0px;
    border: none;
    border-radius: 8px;
    .over_view_img {
        background-color: $white;
        padding: 0;
        border-radius: 50%;
        box-shadow: rgb(14 30 31 / 7%) 0px 2px 4px 0px, rgb(14 24 32 / 22%) 0px 2px 11px 0px;
        img {
            width: 60px;
            border-radius: 50%;
        }
    }
    span {
        color: $primary;
    }
    p {
        margin-bottom: 2px;
    margin-top: 10%;
    color: $primary;
    }
    &:first-child {
        margin-left: 20px;
    }
    &:last-child {
        margin-right: 20px;
    }
    .header_ethereum {
        width: 10px;
        margin-right: 12px;
    }
}

p.over_view_head_content {
    padding: 30px 20px 0;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
}
.count_down_sec {
    margin-top: 36px;
}

.count_timer {
    background-color: $blue;
    padding: 30px;
    h3 {
        text-align: center;
        font-family: $font-medium;
        font-size: 36px;
        color: $primary;
        margin-top: 4%;
    }
    ul {
        display: flex;
    padding-left: 0px;
    list-style-type: none;
    justify-content: space-around;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    li {
        p {
            color: $primary;
            margin-bottom: 0px;
            font-size: 42px;
        }
        span {
            color: $primary;
        }
    }
    }
}
.amount_claimed_sec {
    width: 30%;
    text-align: left;
    margin: auto;
    padding: 20px;
    ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        background-color: #f6f6f6;
        border: 2px solid #d5ddeb;
        padding: 20px;
         li {
            font-family: $font-medium;
            p {
                color:$primary;
                margin-bottom: 4px;
            }
            img {
                width: 26px;
                margin-right: 20px;
            }
            button {
                background-color: #303030;
                color: $white;
                padding: 8px 36px 8px 36px;
                font-size: 20px;
                border: none;
                &:hover {
                    background-color: #303030;
                    border: none;
                }
                &:active {
                    background-color: #303030 !important;
                    border: none;
                }
            }
         }
    }
}
.count_down_flex {
    display: flex;
  justify-content: space-around;
  font-family: sans-serif;
  text-align: center;
  padding-top: 40px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 7%;
}
.nodata {
    text-align: center;
    .loader-container {
        margin: 20px 0 10px;
        height: 20px;
    }
    p {
        font-size: 16px;
        margin: 30px 0 0;
        padding: 0;
    }
}
@media (max-width:768px) {
    .header-container {
        .right-side  {
            .dropdown  {
                ul {
                    li {
                        .header_profile_image {
                            width: 55px;
                        }
                        p {
                            font-size: 16px;
                        }
                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
            .dropdown-menu {
                a {
                    font-size: 16px;
                }
            }
        }
    }
    .over_grid_box {
        grid-template-columns: repeat(6, 1fr);
        .over_view_box_sec {
            grid-column: span 2;
            &:nth-last-child(2):nth-child(3n+1) {
                grid-column-end: 4;
            }
            &:last-child:nth-child(3n-1) {
                grid-column-end: -2;
            }
            &:nth-child(4) {
                margin-top: 20px;
            }
            &:last-child {
                margin-top: 20px;
            }
            
        }
    }
    .count_timer {
        h3 {
            font-size: 32px;
            margin-top: 10%;
        }
        ul {
            li {
                padding-right: 20px;
                p {
                    font-size: 34px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .count_down_flex {
        width: 100%;
        div{
            svg{
                width: 100px;
            }
        }
        
    }
}
@media (max-width:576px) {
    .over_grid_box {
        .over_view_box_sec {

            p {
                font-size: 14px;
            }
            span {
                font-size: 14px;
            }
            .over_view_img {
                padding: 14px 13px;
    padding-bottom: 17px;
                img {
                    width: 24px;
                }
            }

        }
    }
    .count_down_flex {
        width: 100%;
        div{
            svg{
                width: 80px;
            }
            .time-wrapper {
                font-size: 12px;
            }
        }
        
    }
}
@media (max-width:480px) {
    .header-container {
        padding: 20px 12px;
        .logo {
            img {

            }
        }
        .right-side  {
            .dropdown  {
                ul {
                    li {
                        .header_profile_image {
                            width: 50px;
                        }
                        p {
                            font-size: 15px;
                        }
                        span {
                            font-size: 15px;
                        }
                        &:nth-child(2) {
                            padding-right: 25px;
                        }
                        .drop_down_icon {
                            width:14px;
                        }
                    }
                }
            }
            .dropdown-menu {
                inset: 0px auto auto 68px !important;

                a {
                    font-size: 15px;
                }
            }

        }
    }
    .over_grid_box {
        display: block;
        .over_view_box_sec {
            margin-top: 26px;
        }
    }
    .count_timer {
        padding-right: 19px;
        h3 {
            font-size: 30px;
            margin-top: 15%;
        }
        ul {
            li {
                padding-right: 20px;
                p {
                    font-size: 30px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .count_down_flex {
        width: 100%;
        div{
            svg{
                width: 70px;
            }
            .time-wrapper {
                font-size: 10px;
            }
        }
    }
}

@media (max-width:360px) {
    .count_timer{
        padding: 5px;
        padding-right: 12px;
    }
    .count_down_flex {
        width: 100%;
        div{
            svg{
                width: 60px;
            }
            .time-wrapper {
                font-size: 10px;
            }
        }
    }
}