@font-face {
    font-family: 'Open-Sans-light';
    src: url('../fonts/OpenSans-Light.eot');
    src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff'),
        url('../fonts/OpenSans-Light.ttf') format('truetype'),
        url('../fonts/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open-Sans-medium';
    src: url('../fonts/OpenSans-Medium.eot');
    src: url('../fonts/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Medium.woff2') format('woff2'),
        url('../fonts/OpenSans-Medium.woff') format('woff'),
        url('../fonts/OpenSans-Medium.ttf') format('truetype'),
        url('../fonts/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-regular';
    src: url('../fonts/OpenSans-Regular.eot');
    src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff'),
        url('../fonts/OpenSans-Regular.ttf') format('truetype'),
        url('../fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-bold';
    src: url('../fonts/OpenSans-Bold.eot');
    src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff'),
        url('../fonts/OpenSans-Bold.ttf') format('truetype'),
        url('../fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Open-Sans-semibold';
    src: url('../fonts/OpenSans-SemiBold.eot');
    src: url('../fonts/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/OpenSans-SemiBold.woff') format('woff'),
        url('../fonts/OpenSans-SemiBold.ttf') format('truetype'),
        url('../fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@import './variable.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');

body {
  width: 100%;
  height: 100vh;
  font-family: Open Sans;
}

#root {
  width: 100%;
  height: 100vh;
}

.mainContainer {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.layerContainer {
  position: relative;
  overflow: hidden;
}

.positionContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.positionContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.atm-overlay {
  position: absolute;
  top: 0;
}

.screen-layer {
  width: 6.55%;
  height: 5.6%;
  position: absolute;
  left: 61%;
  top: 45%;
}

.pad-layer {
  z-index: 1;
  width: 5.30%;
  height: 3.84%;
  position: absolute;
  left: 60.35%;
  top: 52.2%;
  transform: skewX(-22deg);
}

.getting_start {
  text-align: center;
  z-index: 1;
  position: relative;
}

.getting_start h3 {
  color: #fff;
  text-shadow: 0 1px #333333, -1px 0 #cdd2d5, -1px 2px #333333, -2px 1px #333333, -2px 3px #333333, -3px 2px #333333, -3px 4px #333333;
  font-size: 0.8rem;
  margin: 0 0 15px;
}

.getting_start button {
  background-color: transparent;
  border: 1px solid transparent;
  color: #fff;
  text-transform: uppercase;
  padding: 7px 20px;
  border-radius: 4px;
  width: 80%;
  position: relative;
  font-size: 0.8rem;
  cursor: pointer;
  font-weight:600;
}

.getting_start button::before {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #F27603;
  z-index: -1;
  left: -7px;
  bottom: -4px;
  border-radius: 4px;
}

.getting_start button::after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #FE7E08;
  z-index: -1;
  left: 0px;
  bottom: 0;
  border-radius: 4px;
}

.earned_cash {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.earned_cash.active {
  display: block;
}

.earned_cash.inactive {
  display: none;
}

.earned_cash h5 {
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.2rem;
  margin: 4px 0 0;
  font-weight: 500;
}

.earned_cash h3 {
  color: #fff;
  font-size: 3.5rem;
  line-height: 3.5rem;
  margin: 0 0 6px;
  -webkit-text-stroke: 1px black;
  font-weight: 600;
}

.earned_cash p {
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.4rem;
  margin: 0;
  text-transform: uppercase;
}
.claim {
  display: flex;
  height: 100%;
}
.claim button {
  background-color: #1A8A07;
  border: 5px solid #666666;
  width: 100%;
  padding: 0 10px;
  text-transform: uppercase;
  border-radius: 5px 5px 5px 8px;
  color: #fff;
  display: block;
  font-size: 2rem;
  cursor: pointer;
  font-weight: 600;
}
.claim button:hover {
    background-color: #1A8A07;
    border: 5px solid #666666;
}

.claim button:active, .claim button:first-child:active, .claim button:focus, .claim.active button {
    background-color: #fff;
    color: #666666;
    border-color: #666666;
}
.claimed {
  height:100%;
}
.claimed.active {
  display: block;
}

.claimed h3 {
  color: #fff;
    font-size: 2.5rem;
    line-height: normal;
    margin: 0;
    text-transform: uppercase;
    font-weight: 700;
}

div.header-container {
  background: #fff;
  padding: 20px 0;
  border-radius: 0 0 20px 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0;
  z-index: 9;
  position: absolute;
}

.left-head {
  padding: 0 30px;
}

.right-head {
  padding: 0 30px;
}

.left-head img {
  width: 50px;
}

.right-head .dropdown button {
  background-color: transparent;
  border: none;
  padding: 0 30px 0 0;
  color: #4a4a4a;
  display: flex;
  align-items: center;
  position: relative;
}

.right-head .dropdown button .user_img {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  object-fit: cover;
  margin: 0 15px 0 0;
}

.right-head .dropdown button .add_right_wallet {
  font-size: 14px;
  font-family: Open Sans;
  text-align: left;
}

.right-head .dropdown button .add_right_wallet span {
  display: block;
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
}

.right-head .dropdown button .add_right_wallet span .bitcoin_img {
  width: 15px;
  vertical-align: -2px;
  margin: 0 2px 0 0;
}

.right-head .dropdown button.dropdown-toggle::after {
  display: none;
}

.right-head .dropdown button.dropdown-toggle::before {
  width: 10px;
  height: 10px;
  transition: transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  transform: rotate(134deg);
  content: " ";
  position: absolute;
  right: 0;
  display: inline-block;
}

.right-head .show.dropdown button.dropdown-toggle::before {
  transform: rotate(-45deg);
}

.right-head .show.dropdown .dropdown-menu {
  display: flex;
}

.right-head .dropdown .dropdown-menu {
  flex-direction: column;
  display: none;
  border: none;
  border-radius: 0 0 15px 15px;
  padding: 15px;
  background-color: #fff;
}
.left_wallet {
    margin: 0 10px 0 0;
}
.right-head .dropdown .dropdown-menu a {
  text-decoration: none;
}

.connect_wallet {
  background-color: #FF7B27;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #FF7B27;
  font-family: Open Sans;
}

.right-head .dropdown .dropdown-menu .logout {
  color: #4a4a4a;
  font-size: 16px;
  margin:0;
  display: inline-block;
  background: none;
  padding: 0;
}

.logout_img {
  width: 14px;
  vertical-align: middle;
  margin: 0 2px 0 0;
}

.hide {
  display: none;
}
.claimloader .nodata .loader-container {
    margin: 20px auto 10px;
}
.claimloader .nodata .loader-container span {margin:0 auto;}
.claimloader .nodata p {
    font-weight: 600;
    font-size: 18px;
    color:#fff;
    margin: 35px 0 0;
}
.right-head .dropdown button.dropdown-toggle {
    background: none;
    color: #4a4a4a;
}

.board-layer {
  width: 11.3%;
  height: 20.84%;
  background: white;
  position: absolute;
  left: 27.95%;
  top: 54.6%;
  transform: skew(9deg);
  z-index: 1;
  border-radius: 4px;
}
.board-layer::before {
  content: " ";
  background-color: #DDDDDD;
  position: absolute;
  left: -7px;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -9;
  border-radius: 4px;
}
.board-layer::after {
  content: " ";
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  border-radius: 4px;
}
.white_board_box_one {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.claimloader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.center_claimed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media(max-width:1199px) {
.getting_start h3 {
  font-size: 0.7rem;
}  
.getting_start button {
  font-size: 0.7rem;
  width: 75%;
}
.earned_cash h5 {
  font-size: 1rem;
  line-height: 1rem;
  margin: 4px 0 0;
}
.earned_cash h3 {
  font-size: 3rem;
  line-height: 3rem;
  margin:0 0 4px;
}
.earned_cash p {
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.claimed h3 {
font-size: 2.1rem;;
}
}

@media(max-width:991px) {
  .earned_cash h3 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
}

@media(max-width:480px) {
  .left-head {padding:0 15px;}  
  .right-head {padding:0 15px;}  
}