.header-container{
    background:#fff;
    padding:12px 30px;
    box-shadow: rgb(0 0 0 / 7%) 0px 15px 25px -5px, rgb(0 0 0 / 3%) 0px 10px 10px -5px;
    .logo{
        img{
            max-width:170px;
        }
        @media(max-width:767px) {
           padding:0 0 10px; 
        }
    }
    .right-side {
        .dropdown {
            .dropdown-toggle {
                background-color: transparent;
                border: none;
                padding: 0px;
                height: 65px;
                &::after {
                    border: none;
                }
            }
            ul {
                display: flex;
                list-style-type: none;
                padding-left: 0px;
                align-items: center;
                margin-bottom: 0px;
                li {
                    padding-right: 16px;
                    text-align: initial;
                    p {
                        margin-bottom: 0px;
                        color: $primary;
                        font-size: 18px;
                    }
                    span {
                        color: $primary;
                        font-size: 18px;
                    }
                    .header_profile_image {
                        width: 60px;
                    }
                    &:last-child{
                        padding-right: 0px;
                    }
                    &:nth-child(2) {
                        padding-right: 20px;
                    }
                    .drop_down_icon {
                        width: 20px;
                    }
                    .header_ethereum {
                        width: 12px;
                        margin-right: 12px;
                    }
                }
            }
        }
        .dropdown-menu {
            inset: 0px auto auto 122px !important;
            --bs-dropdown-min-width: 8rem;
            box-shadow: rgb(0 0 0 / 0%) 0px 54px 55px, rgb(0 0 0 / 0%) 0px -12px 30px, rgb(0 0 0 / 9%) 0px 4px 6px, rgb(0 0 0 / 10%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px;
            border: none;
            a {
                font-size: 18px;
                &:active{
                    background-color: transparent;
                    color: $primary;
                }
                img {
                    margin-right: 15px;
                    width: 16px;
                }
            }
        }
    }
    .guest-container {
        flex-direction:row;
        .guest-container-inner {
            max-width:none;
            flex-direction:row;
            height: auto;
            align-items: center;
            br {
                display:none;
            }
            h1 {
                font-size: 14px;
                margin: 0;
                padding:0 10px 0 0;
                 a {
                    color: #000;
                 }
                 @media(max-width:480px) {
                    padding:0 0 10px 0;
                 }
            }
            input {
                background: #F7931E !important;
                width: auto;
                padding: 8px 15px;
                border: 2px solid #000;
                color: #000;
                box-shadow: none;
                margin: 0 10px 0 0;
                font-size: 14px;
                height: 40px;
                &::-webkit-input-placeholder {
                    color: #000;opacity:1;
                  }
                &::-moz-placeholder {
                    color: #000;opacity:1;
                  }
                &:-ms-input-placeholder { 
                    color: #000;opacity:1;
                  }
                &:-moz-placeholder { 
                    color: #000;opacity:1;
                  }
                  @media(max-width:480px) {
                    margin: 0 0 10px;
                  }
            }
            .btn-danger {
                background-color: transparent;
                border: 2px solid #000;
                border-radius: 0;
                color: #000;
                box-shadow: none;
                padding: 7px 15px;
                font-size: 14px;
                height: 40px;
            }
            @media(max-width:480px) {
                flex-direction: column;
            }
        }
    }
    @media(max-width:767px) {
    //  flex-direction: column;
    //  align-items: center;
    }
}





